var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[(!_vm.successRegistration)?_c('div',{staticClass:"login__container"},[_c('h1',{staticClass:"login__title"},[_vm._v("Registration")]),(_vm.template)?_c('div',{staticClass:"login__types"},_vm._l((Object.keys(_vm.template)),function(item,index){return _c('div',{key:index,class:[
          'login__type',
          _vm.template[item].id == 2 && 'show',
          _vm.template[item].id == _vm.formDataToSend.group_id && 'active',
        ],on:{"click":function($event){return _vm.handleChooseType(_vm.template[item].id)}}},[(_vm.template[item].id == 2)?[_vm._v(" "+_vm._s(_vm.template[item].name)+" ")]:_vm._e()],2)}),0):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"input-container"},[_c('InputText',{attrs:{"set-value":_vm.formDataToSend,"id":'name',"label":'name',"type":'name',"placeholder":'Name',"error-messages":_vm.validationErrors['metaName'],"isBordered":"","isAverage":""}})],1),_c('div',{staticClass:"input-container"},[_c('InputText',{attrs:{"set-value":_vm.formDataToSend,"id":'email',"label":'email',"type":'email',"placeholder":'Email',"error-messages":_vm.validationErrors['metaEmail'],"isBordered":"","isAverage":""}})],1),_c('div',{staticClass:"input-container"},[_c('InputText',{attrs:{"set-value":_vm.formDataToSend,"id":'password',"label":'password',"type":'password',"placeholder":'Password',"error-messages":_vm.validationErrors['metaPassword'],"isBordered":"","isAverage":""}})],1),(_vm.template)?_vm._l((Object.keys(_vm.template)),function(item,index){return _c('div',{key:index},[(_vm.template[item].id && _vm.template[item].id == _vm.formDataToSend.group_id)?_vm._l((Object.keys(_vm.template[item].fields)),function(e,i){return _c('div',{key:i,staticClass:"input-container"},[(_vm.template[item].fields[e].type == 'text')?[_c('InputText',{attrs:{"set-value":_vm.formDataToSend[item],"id":e,"label":e,"type":_vm.template[item].fields[e].type,"placeholder":_vm.template[item].fields[e].name,"error-messages":_vm.validationErrors[
                      `meta${item == 'CustomerAdmin' ? 'Customer' : 'Supplier'}${
                        e.charAt(0).toUpperCase() + e.slice(1)
                      }`
                    ],"isBordered":"","isAverage":""}})]:_vm._e(),(_vm.template[item].fields[e].type == 'select')?[_c('MainSelect',{attrs:{"id":e,"placeholder":_vm.template[item].fields[e].name,"option-list":_vm.template[item].fields[e].options,"set-value":_vm.formDataToSend[item],"value":_vm.formDataToSend[item][e],"error-messages":_vm.validationErrors[
                      `meta${item == 'CustomerAdmin' ? 'Customer' : 'Supplier'}${
                        e.charAt(0).toUpperCase() + e.slice(1)
                      }`
                    ]}})]:_vm._e()],2)}):_vm._e()],2)}):_vm._e(),(_vm.error.message != undefined)?_c('div',{staticClass:"form-create__error"},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e(),_c('MainButton',{attrs:{"type":'submit'}},[_vm._v(" Send ")])],2)]):_c('div',{staticClass:"login__container"},[_c('h1',{staticClass:"login__title"},[_vm._v(" You have successfully registered. Wait for confirmation by managers ")])]),(_vm.loadingProcess)?_c('FetchSpinnerModal'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }