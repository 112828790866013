<template>
  <div class="login">
    <div v-if="!successRegistration" class="login__container">
      <h1 class="login__title">Registration</h1>
      <div v-if="template" class="login__types">
        <div
          v-for="(item, index) in Object.keys(template)"
          :key="index"
          :class="[
            'login__type',
            template[item].id == 2 && 'show',
            template[item].id == formDataToSend.group_id && 'active',
          ]"
          @click="handleChooseType(template[item].id)"
        >
          <template v-if="template[item].id == 2">
            {{ template[item].name }}
          </template>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'name'"
            :label="'name'"
            :type="'name'"
            :placeholder="'Name'"
            :error-messages="validationErrors['metaName']"
            isBordered
            isAverage
          />
        </div>
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'email'"
            :label="'email'"
            :type="'email'"
            :placeholder="'Email'"
            :error-messages="validationErrors['metaEmail']"
            isBordered
            isAverage
          />
        </div>
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'password'"
            :label="'password'"
            :type="'password'"
            :placeholder="'Password'"
            :error-messages="validationErrors['metaPassword']"
            isBordered
            isAverage
          />
        </div>
        <template v-if="template">
          <div v-for="(item, index) in Object.keys(template)" :key="index">
            <template v-if="template[item].id && template[item].id == formDataToSend.group_id">
              <div
                class="input-container"
                v-for="(e, i) in Object.keys(template[item].fields)"
                :key="i"
              >
                <template v-if="template[item].fields[e].type == 'text'">
                  <InputText
                    :set-value="formDataToSend[item]"
                    :id="e"
                    :label="e"
                    :type="template[item].fields[e].type"
                    :placeholder="template[item].fields[e].name"
                    :error-messages="
                      validationErrors[
                        `meta${item == 'CustomerAdmin' ? 'Customer' : 'Supplier'}${
                          e.charAt(0).toUpperCase() + e.slice(1)
                        }`
                      ]
                    "
                    isBordered
                    isAverage
                  />
                </template>
                <template v-if="template[item].fields[e].type == 'select'">
                  <MainSelect
                    :id="e"
                    :placeholder="template[item].fields[e].name"
                    :option-list="template[item].fields[e].options"
                    :set-value="formDataToSend[item]"
                    :value="formDataToSend[item][e]"
                    :error-messages="
                      validationErrors[
                        `meta${item == 'CustomerAdmin' ? 'Customer' : 'Supplier'}${
                          e.charAt(0).toUpperCase() + e.slice(1)
                        }`
                      ]
                    "
                  />
                </template>
              </div>
            </template>
          </div>
        </template>
        <div v-if="error.message != undefined" class="form-create__error">
          {{ error.message }}
        </div>
        <MainButton :type="'submit'"> Send </MainButton>
      </form>
    </div>
    <div v-else class="login__container">
      <h1 class="login__title">
        You have successfully registered. Wait for confirmation by managers
      </h1>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import usersApi from "~/api/user";

import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";

export default {
  name: "Registration",
  metaInfo: {
    title: "Registration",
  },
  data() {
    return {
      formDataToSend: {
        group_id: 2,
        name: null,
        email: null,
        password: null,
        consumerAdmin: {},
        supplierAdmin: {
          type: null,
          name: null,
        },
      },
      template: null,
    };
  },
  mounted() {
    this.getField();
  },
  methods: {
    handleSubmit() {
      const data = {
        group_id: this.formDataToSend.group_id,
        meta: {
          name: this.formDataToSend.name,
          email: this.formDataToSend.email,
          password: this.formDataToSend.password,
        },
      };
      if (this.formDataToSend.group_id == 2) {
        data.meta.supplier = {
          type: this.formDataToSend.supplierAdmin?.type?.name,
          name: this.formDataToSend.supplierAdmin.name,
        };
      } else if (this.formDataToSend.group_id == 4) {
      }
      this.$store.dispatch("users/registration", data);
    },
    getField() {
      try {
        usersApi.getRegistrationField().then((res) => {
          this.$store.commit("users/setError", {});
          this.$store.commit("users/setValidationErrors", {});
          this.template = res.data.data.template;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleChooseType(data) {
      this.formDataToSend.group_id = data;
    },
  },
  components: {
    MainButton,
    InputText,
    FetchSpinnerModal,
    MainSelect,
  },
  computed: {
    ...mapState("users", ["error", "validationErrors", "successRegistration", "loadingProcess"]),
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__container {
    width: 400px;
    padding-bottom: 150px;
  }

  &__types {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-around;
  }

  &__type {
    display: none;
    background: #fff;
    border: 2px solid $coffee;
    border-radius: 5px;
    padding: 10px 15px;
    color: $coffee;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &.show {
      display: block;
    }

    &.active {
      background: $coffee;
      color: #fff;
    }
  }

  &__link {
    color: #3a80f9;
    text-align: center;
    font-size: 18px;
    width: 100%;
    display: block;
  }

  &__title {
    padding-bottom: 20px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: $mainSecondTextColor;
  }

  &__choose-site {
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }
}
.input-container {
  padding-bottom: 20px;
}
</style>
